const ROUTES = {
  "/": {
    "name": "HomePageEffef9c7C97e459f941dDf47b8394de7",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages38f60a6dC3ce4c37B1ec81429b3a1db8",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPageBb38bb63063a4e35B577F30fd1fa8fb6",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPageA9f4299cF6ca40689498317c68535074",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPageC8f28ac4F4564b288bc8F5eb1e77e87f",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPageC4571c03B07b4c129cf243b440d43242",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPagesF9a32d4e24cd46ccA2a5C9df273da98f",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages2218919406f74062Ba7fDe67c1fc1efd",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPage610bd52eE1b14470B0a80987010ea33b",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPagesD05e15ce000d46a0A462E3d0ab8b9bb3",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;