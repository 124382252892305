import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "048e2672-3327-43e4-9ca6-3ee4009898ae"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards048e2672332743e49ca63ee4009898ae(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards048e2672332743e49ca63ee4009898ae", parentTag)} postSlug="home-page-posts2" buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "08f22a0a-84f9-40d3-b3f5-4b1989f6a81d"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "23daed14-737f-4b4b-a0fb-dea20e35ce96"
export const WidgetId08f22a0a84f940d3B3f54b1989f6a81d = "4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d";

// id: "40985e29-3f3d-4989-ae06-398da5804f05"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const Title40985e293f3d4989Ae06398da5804f05 = "Leveling";

// id: "d9c68fe5-608f-46a0-9982-f2a3e042bcae"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "41017c01-6500-47a9-87ed-ea8e02dbede4"
export const WidgetIdD9c68fe5608f46a09982F2a3e042bcae = "6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5";

// id: "38f60a6d-c3ce-4c37-b1ec-81429b3a1db8"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages38f60a6dC3ce4c37B1ec81429b3a1db8(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages38f60a6dC3ce4c37B1ec81429b3a1db8`}>
      {props.children}
    </div>
  );
}

// id: "eb93e62e-c7d4-4ebf-a9b0-bbeb21503a41"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeaderEb93e62eC7d44ebfA9b0Bbeb21503a41(props: any) {
  return (
    <ShortHeaderF864390b920c4cd4Ad826381809b2638 parentTag="DefaultHeaderEb93e62eC7d44ebfA9b0Bbeb21503a41" title navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "83871dd2-6cde-4608-a866-45fa58c5f144"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader83871dd26cde4608A86645fa58c5f144(props: any) {
  return (
    <ShortHeaderF864390b920c4cd4Ad826381809b2638 parentTag="MembersAreaHeader83871dd26cde4608A86645fa58c5f144" header-top={<DefaultHeaderTopBf5b0da391c04d2bBb163d3294906847 parentTag="HeaderTopC7c3b22a07784385B11d0d12d346008a" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "f720abba-322a-4cc1-8355-75a29292259a"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBodyF720abba322a4cc1835575a29292259a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBodyF720abba322a4cc1835575a29292259a page-body`}>
      {props["marketingCards"] || <MarketingCards048e2672332743e49ca63ee4009898ae parentTag="MarketingCards8eabe1a16b9545c4B4ff9d8ce8794615" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "bb38bb63-063a-4e35-b577-f30fd1fa8fb6"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPageBb38bb63063a4e35B577F30fd1fa8fb6(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPageBb38bb63063a4e35B577F30fd1fa8fb6 page`}>
      {props["header"] || <SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e parentTag="Header5eae32f679b0448dA6e6Dd6d644d8a83" title="About Us" {...props} />}
      {props["body"] || <MarketingBodyF720abba322a4cc1835575a29292259a parentTag="BodyD8208fddB6d2446484af5817638216dd" postSlug="about-us-posts" {...props} />}
      {props["footer"] || <DefaultFooter04031b2680314f888af1B82289fb5995 parentTag="FooterFbf41fc333f04778A22e770d72e4551a" {...props} />}
    </div>
  );
}

// id: "c8f28ac4-f456-4b28-8bc8-f5eb1e77e87f"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPageC8f28ac4F4564b288bc8F5eb1e77e87f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPageC8f28ac4F4564b288bc8F5eb1e77e87f`}>
      {props["header"] || <SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e parentTag="HeaderE93b6be1Eda84890984439422e20be5d" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter04031b2680314f888af1B82289fb5995 parentTag="FooterE3c309297cd94d6380daD6889020c3fc" {...props} />}
    </div>
  );
}

// id: "78ffd4ad-818e-45d6-b2e5-7f35ce225f15"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "04031b26-8031-4f88-8af1-b82289fb5995"
export function Links78ffd4ad818e45d6B2e57f35ce225f15(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("Links78ffd4ad818e45d6B2e57f35ce225f15", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "8af1dfdf-2dba-43fa-9aa1-d1fe7c5dc7c4"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "4e1e1b83-69f7-4fc4-a4b9-95008afa617e"
export function Hero8af1dfdf2dba43fa9aa1D1fe7c5dc7c4(props: any) {
  return (
    <SecondaryMarketingHeroC36ed3d7411641e285bfC96dbc2a6bc0 parentTag="Hero8af1dfdf2dba43fa9aa1D1fe7c5dc7c4" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "f9a32d4e-24cd-46cc-a2a5-c9df273da98f"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPagesF9a32d4e24cd46ccA2a5C9df273da98f(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPagesF9a32d4e24cd46ccA2a5C9df273da98f gf-page-layout`}>
      {props["header"] || <MembersAreaHeader83871dd26cde4608A86645fa58c5f144 parentTag="Header1e300fbcE6e94396Bee5536ac096b405" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav9986106bB8da4dd4Bf24D3f29dea444d", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter04031b2680314f888af1B82289fb5995 parentTag="Footer4208185cE55743b1Be4970cd98577263" {...props} />}
    </div>
  );
}

// id: "89b9d7cc-5089-49a4-a2ae-70b87919316c"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault89b9d7cc508949a4A2ae70b87919316c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault89b9d7cc508949a4A2ae70b87919316c page`}>
      {props["header"] || <MembersAreaHeader83871dd26cde4608A86645fa58c5f144 parentTag="HeaderCa9970b93a8945d780deFcdb7d00a09c" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooter04031b2680314f888af1B82289fb5995 parentTag="Footer1b8bbf8488994dfa80b9C40a69673f44" {...props} />}
    </div>
  );
}

// id: "e1dc4038-93d8-4a34-bca7-032d6974367e"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPageE1dc403893d84a34Bca7032d6974367e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPageE1dc403893d84a34Bca7032d6974367e`}>
      {props["header"] || <SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e parentTag="Header4b7bdd415d1240b48ea2E2caaf917ec2" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooter04031b2680314f888af1B82289fb5995 parentTag="Footer0949722746ee4a6f9a2eC2210d112939" {...props} />}
    </div>
  );
}

// id: "610bd52e-e1b1-4470-b0a8-0987010ea33b"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage610bd52eE1b14470B0a80987010ea33b(props: any) {
  return (
    <SecondaryPageE1dc403893d84a34Bca7032d6974367e parentTag="ResourcesPage610bd52eE1b14470B0a80987010ea33b" header={<SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e parentTag="Header03d2b82a8232476dAdc4D642ddb16d5c" title="Resources" />} {...props} />
  );
}

// id: "f4d52eb4-db3a-4235-bb8f-126125d261a1"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function LabelF4d52eb4Db3a4235Bb8f126125d261a1(props: any) {
  return (
    <DefaultLogoA95cd88c2c904920A94004eb36e311d9 parentTag="LabelF4d52eb4Db3a4235Bb8f126125d261a1" {...props} />
  );
}

// id: "f864390b-920c-4cd4-ad82-6381809b2638"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeaderF864390b920c4cd4Ad826381809b2638(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeaderF864390b920c4cd4Ad826381809b2638 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo5af8a17039944d598928161d70e58455", parentTag)} label={<DefaultLogoA95cd88c2c904920A94004eb36e311d9 parentTag="LabelE933e9c84bc1449cAbdd183df3a603bf" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation2a6b0f7c33ce49c3967e0acf446e1688", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "a95cd88c-2c90-4920-a940-04eb36e311d9"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogoA95cd88c2c904920A94004eb36e311d9(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg" className={`${parentTag || ""} DefaultLogoA95cd88c2c904920A94004eb36e311d9 logo`} alt="logo" />
  );
}

// id: "8b5ba94f-e2c2-44cd-be7e-4087307d45e2"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote8b5ba94fE2c244cdBe7e4087307d45e2(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote8b5ba94fE2c244cdBe7e4087307d45e2`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "c4571c03-b07b-4c12-9cf2-43b440d43242"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPageC4571c03B07b4c129cf243b440d43242(props: any) {
  return (
    <SecondaryPageE1dc403893d84a34Bca7032d6974367e parentTag="JoinUsPageC4571c03B07b4c129cf243b440d43242" header={<SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e parentTag="HeaderE14dfc46E721477d927b39a6a02a4221" title="Join Us" />} {...props} />
  );
}

// id: "c8896faf-2702-483e-9d9d-12179ef7c79e"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const HrefC8896faf2702483e9d9d12179ef7c79e = "/";

// id: "1f3b572f-2282-47d6-922e-a265fc878aec"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function Footer1f3b572f228247d6922eA265fc878aec(props: any) {
  return (
    <DefaultFooter04031b2680314f888af1B82289fb5995 parentTag="Footer1f3b572f228247d6922eA265fc878aec" {...props} />
  );
}

// id: "d05e15ce-000d-46a0-a462-e3d0ab8b9bb3"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPagesD05e15ce000d46a0A462E3d0ab8b9bb3(props: any) {
  return (
    <SecondaryPageE1dc403893d84a34Bca7032d6974367e parentTag="SignupPagesD05e15ce000d46a0A462E3d0ab8b9bb3" header={<SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e parentTag="Header5d41955941f549b8948dF94bada593e3" title="Join Us" />} {...props} />
  );
}

// id: "c36ed3d7-4116-41e2-85bf-c96dbc2a6bc0"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHeroC36ed3d7411641e285bfC96dbc2a6bc0(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeroC36ed3d7411641e285bfC96dbc2a6bc0 hero`}>
      <div className="title">
        {props["title"] || Title689d635b45f64e74A453066a92d8d398}
      </div>
    </div>
  );
}

// id: "b5f4632b-d8a1-41f0-a50a-7c03ce06f2f6"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function HeaderB5f4632bD8a141f0A50a7c03ce06f2f6(props: any) {
  return (
    <MembersAreaHeader83871dd26cde4608A86645fa58c5f144 parentTag="HeaderB5f4632bD8a141f0A50a7c03ce06f2f6" {...props} />
  );
}

// id: "4208185c-e557-43b1-be49-70cd98577263"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "f9a32d4e-24cd-46cc-a2a5-c9df273da98f"
export function Footer4208185cE55743b1Be4970cd98577263(props: any) {
  return (
    <DefaultFooter04031b2680314f888af1B82289fb5995 parentTag="Footer4208185cE55743b1Be4970cd98577263" {...props} />
  );
}

// id: "ca9970b9-3a89-45d7-80de-fcdb7d00a09c"
// title: ""
// type: :reference
// key: "header"
// parent_id: "89b9d7cc-5089-49a4-a2ae-70b87919316c"
export function HeaderCa9970b93a8945d780deFcdb7d00a09c(props: any) {
  return (
    <MembersAreaHeader83871dd26cde4608A86645fa58c5f144 parentTag="HeaderCa9970b93a8945d780deFcdb7d00a09c" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "1e300fbc-e6e9-4396-bee5-536ac096b405"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f9a32d4e-24cd-46cc-a2a5-c9df273da98f"
export function Header1e300fbcE6e94396Bee5536ac096b405(props: any) {
  return (
    <MembersAreaHeader83871dd26cde4608A86645fa58c5f144 parentTag="Header1e300fbcE6e94396Bee5536ac096b405" {...props} />
  );
}

// id: "7c7abcd6-860b-4f1b-8669-9f24ef20fc3b"
// title: ""
// type: :reference
// key: "header"
// parent_id: "effef9c7-c97e-459f-941d-df47b8394de7"
export function Header7c7abcd6860b4f1b86699f24ef20fc3b(props: any) {
  return (
    <HomeHeader4c2bb39657a5474bA35bA14257df8c0b parentTag="Header7c7abcd6860b4f1b86699f24ef20fc3b" {...props} />
  );
}

// id: "da5f0c13-2bbe-43b6-95ee-8038e5f4a11b"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "c7c3b22a-0778-4385-b11d-0d12d346008a"
export const NavMenuSlugDa5f0c132bbe43b695ee8038e5f4a11b = "members-primary-nav";

// id: "32f6cfa8-3cae-4c2e-914a-96576c3c6bea"
// title: ""
// type: :text
// key: "href"
// parent_id: "92bb257f-c2fc-412f-972b-187916821fb3"
export const Href32f6cfa83cae4c2e914a96576c3c6bea = "/join";

// id: "61ff1163-2b0b-4019-8d33-956427ed8bae"
// title: ""
// type: :text
// key: "title"
// parent_id: "e93b6be1-eda8-4890-9844-39422e20be5d"
export const Title61ff11632b0b40198d33956427ed8bae = "Public Events";

// id: "04031b26-8031-4f88-8af1-b82289fb5995"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooter04031b2680314f888af1B82289fb5995(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooter04031b2680314f888af1B82289fb5995 footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("Links78ffd4ad818e45d6B2e57f35ce225f15", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <LogoAcb582b347b64d8eBf3c90613b5d6cbe />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright1ce0cc7fBb4e45f19a7f6ee26677b84c", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("GroupflowF1f1fb690bf84b648e9710cf312d470d", parentTag)} {...props} />}
    </div>
  );
}

// id: "42fb0eab-f972-48d5-b8e5-49c38d07c16b"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "effef9c7-c97e-459f-941d-df47b8394de7"
export function WelcomeBack42fb0eabF97248d5B8e549c38d07c16b(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack42fb0eabF97248d5B8e549c38d07c16b", parentTag)} {...props} />
  );
}

// id: "fbf41fc3-33f0-4778-a22e-770d72e4551a"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "bb38bb63-063a-4e35-b577-f30fd1fa8fb6"
export function FooterFbf41fc333f04778A22e770d72e4551a(props: any) {
  return (
    <DefaultFooter04031b2680314f888af1B82289fb5995 parentTag="FooterFbf41fc333f04778A22e770d72e4551a" {...props} />
  );
}

// id: "1b8bbf84-8899-4dfa-80b9-c40a69673f44"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "89b9d7cc-5089-49a4-a2ae-70b87919316c"
export function Footer1b8bbf8488994dfa80b9C40a69673f44(props: any) {
  return (
    <DefaultFooter04031b2680314f888af1B82289fb5995 parentTag="Footer1b8bbf8488994dfa80b9C40a69673f44" {...props} />
  );
}

// id: "58bed9ad-c8be-48da-9f9a-319944725db8"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "effef9c7-c97e-459f-941d-df47b8394de7"
export function Footer58bed9adC8be48da9f9a319944725db8(props: any) {
  return (
    <DefaultFooter04031b2680314f888af1B82289fb5995 parentTag="Footer58bed9adC8be48da9f9a319944725db8" {...props} />
  );
}

// id: "4b7bdd41-5d12-40b4-8ea2-e2caaf917ec2"
// title: ""
// type: :reference
// key: "header"
// parent_id: "e1dc4038-93d8-4a34-bca7-032d6974367e"
export function Header4b7bdd415d1240b48ea2E2caaf917ec2(props: any) {
  return (
    <SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e parentTag="Header4b7bdd415d1240b48ea2E2caaf917ec2" title="Secondary Page" {...props} />
  );
}

// id: "45459fc2-7e95-42b9-907f-66d83b16aaa4"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "048e2672-3327-43e4-9ca6-3ee4009898ae"
export const PostSlug45459fc27e9542b9907f66d83b16aaa4 = "home-page-posts2";

// id: "7e9c380a-7b41-4d5b-b0fe-888c3b6b42bc"
// title: ""
// type: :text
// key: "label"
// parent_id: "92bb257f-c2fc-412f-972b-187916821fb3"
export const Label7e9c380a7b414d5bB0fe888c3b6b42bc = "Join Us";

// id: "c5e5d963-4753-445a-8d92-6b70ac66f97f"
// title: ""
// type: :text
// key: "url"
// parent_id: "85f13516-1d99-457d-8a28-b50c4f15ed2d"
export const UrlC5e5d9634753445a8d926b70ac66f97f = "/";

// id: "1781d1e9-fdb9-4a73-a8b8-ad81f5aa6a67"
// title: ""
// type: :text
// key: "class"
// parent_id: "27071b54-b68d-425f-8568-357957fbe0e7"
export const Class1781d1e9Fdb94a73A8b8Ad81f5aa6a67 = "navigation";

// id: "bf5b0da3-91c0-4d2b-bb16-3d3294906847"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTopBf5b0da391c04d2bBb163d3294906847(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTopBf5b0da391c04d2bBb163d3294906847 header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo85f135161d99457d8a28B50c4f15ed2d", parentTag)} url="/" className="linked-logo" label={<DefaultLogoA95cd88c2c904920A94004eb36e311d9 parentTag="Label268afec013ef4c06931e204a401c79f8" alt="logo" />} {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation27071b54B68d425f8568357957fbe0e7", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "a9f4299c-f6ca-4068-9498-317c68535074"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPageA9f4299cF6ca40689498317c68535074(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPageA9f4299cF6ca40689498317c68535074`}>
      {props["header"] || <SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e parentTag="Header15524cf70a1942fcAdeb9b76f7f42ffa" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter04031b2680314f888af1B82289fb5995 parentTag="FooterCd6e1105A66943c1826d0bd89712463a" {...props} />}
    </div>
  );
}

// id: "f9f3180c-8b86-4fa6-b5a1-1075acafeaeb"
// title: ""
// type: :reference
// key: "header"
// parent_id: "22189194-06f7-4062-ba7f-de67c1fc1efd"
export function HeaderF9f3180c8b864fa6B5a11075acafeaeb(props: any) {
  return (
    <MembersAreaHeader83871dd26cde4608A86645fa58c5f144 parentTag="HeaderF9f3180c8b864fa6B5a11075acafeaeb" {...props} />
  );
}

// id: "85f13516-1d99-457d-8a28-b50c4f15ed2d"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "bf5b0da3-91c0-4d2b-bb16-3d3294906847"
export function LinkedLogo85f135161d99457d8a28B50c4f15ed2d(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo85f135161d99457d8a28B50c4f15ed2d", parentTag)} url="/" className="linked-logo" label={<DefaultLogoA95cd88c2c904920A94004eb36e311d9 parentTag="Label268afec013ef4c06931e204a401c79f8" alt="logo" />} {...props} />
  );
}

// id: "8e4259f0-c0fd-42cd-b703-9f83b8215143"
// title: ""
// type: :reference
// key: "body"
// parent_id: "effef9c7-c97e-459f-941d-df47b8394de7"
export function Body8e4259f0C0fd42cdB7039f83b8215143(props: any) {
  return (
    <MarketingBodyF720abba322a4cc1835575a29292259a parentTag="Body8e4259f0C0fd42cdB7039f83b8215143" {...props} />
  );
}

// id: "15524cf7-0a19-42fc-adeb-9b76f7f42ffa"
// title: ""
// type: :reference
// key: "header"
// parent_id: "a9f4299c-f6ca-4068-9498-317c68535074"
export function Header15524cf70a1942fcAdeb9b76f7f42ffa(props: any) {
  return (
    <SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e parentTag="Header15524cf70a1942fcAdeb9b76f7f42ffa" title="Leveling" {...props} />
  );
}

// id: "1d2bbe57-09ac-43ab-94e9-95f80b380ecf"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "75b58819-b0c4-4a3c-8a42-28246dd5763e"
export const ContentSlug1d2bbe5709ac43ab94e995f80b380ecf = "home-page-quote";

// id: "7c249bf2-8a33-493b-b62c-70f9d1af8593"
// title: ""
// type: :text
// key: "title"
// parent_id: "4b7bdd41-5d12-40b4-8ea2-e2caaf917ec2"
export const Title7c249bf28a33493bB62c70f9d1af8593 = "Secondary Page";

// id: "fa74a19a-e093-40af-8960-01d664336f05"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "78ffd4ad-818e-45d6-b2e5-7f35ce225f15"
export const NavMenuSlugFa74a19aE09340af896001d664336f05 = "footer-nav";

// id: "23daed14-737f-4b4b-a0fb-dea20e35ce96"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "4c2bb396-57a5-474b-a35b-a14257df8c0b"
export function Hero23daed14737f4b4bA0fbDea20e35ce96(props: any) {
  return (
    <HomePageHeroA16cb503Bb564e6885c39fbb57507120 parentTag="Hero23daed14737f4b4bA0fbDea20e35ce96" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />
  );
}

// id: "22189194-06f7-4062-ba7f-de67c1fc1efd"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages2218919406f74062Ba7fDe67c1fc1efd(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages2218919406f74062Ba7fDe67c1fc1efd`}>
      {props["header"] || <MembersAreaHeader83871dd26cde4608A86645fa58c5f144 parentTag="HeaderF9f3180c8b864fa6B5a11075acafeaeb" {...props} />}
      {props.children}
    </div>
  );
}

// id: "a16cb503-bb56-4e68-85c3-9fbb57507120"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroA16cb503Bb564e6885c39fbb57507120(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHeroA16cb503Bb564e6885c39fbb57507120 hero`}>
      <div className="title">
        Our mission is to change the world by promoting healthy intimacy through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button92bb257fC2fc412f972b187916821fb3", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "da87b3bf-a6f6-4842-a4fc-db5799ccf5ef"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "048e2672-3327-43e4-9ca6-3ee4009898ae"
export const ButtonClassDa87b3bfA6f64842A4fcDb5799ccf5ef = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "9986106b-b8da-4dd4-bf24-d3f29dea444d"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "f9a32d4e-24cd-46cc-a2a5-c9df273da98f"
export function SecondaryNav9986106bB8da4dd4Bf24D3f29dea444d(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav9986106bB8da4dd4Bf24D3f29dea444d", parentTag)} {...props} />
  );
}

// id: "1ce0cc7f-bb4e-45f1-9a7f-6ee26677b84c"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "04031b26-8031-4f88-8af1-b82289fb5995"
export function Copyright1ce0cc7fBb4e45f19a7f6ee26677b84c(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright1ce0cc7fBb4e45f19a7f6ee26677b84c", parentTag)} {...props} />
  );
}

// id: "5af8a170-3994-4d59-8928-161d70e58455"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "f864390b-920c-4cd4-ad82-6381809b2638"
export function LinkedLogo5af8a17039944d598928161d70e58455(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo5af8a17039944d598928161d70e58455", parentTag)} label={<DefaultLogoA95cd88c2c904920A94004eb36e311d9 parentTag="LabelE933e9c84bc1449cAbdd183df3a603bf" alt="logo" />} {...props} />
  );
}

// id: "5d419559-41f5-49b8-948d-f94bada593e3"
// title: ""
// type: :reference
// key: "header"
// parent_id: "d05e15ce-000d-46a0-a462-e3d0ab8b9bb3"
export function Header5d41955941f549b8948dF94bada593e3(props: any) {
  return (
    <SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e parentTag="Header5d41955941f549b8948dF94bada593e3" title="Join Us" {...props} />
  );
}

// id: "19d1103e-a9c1-4027-ad18-c10d788160d1"
// title: ""
// type: :text
// key: "title"
// parent_id: "eb93e62e-c7d4-4ebf-a9b0-bbeb21503a41"
export const Title19d1103eA9c14027Ad18C10d788160d1 = null;

// id: "2712ae38-cb43-46b2-8097-440873b1fd74"
// title: ""
// type: :text
// key: "class"
// parent_id: "85f13516-1d99-457d-8a28-b50c4f15ed2d"
export const Class2712ae38Cb4346b28097440873b1fd74 = "linked-logo";

// id: "f1a121e1-676b-4f7b-b034-86ba4a4f3333"
// title: ""
// type: :text
// key: "title"
// parent_id: "8af1dfdf-2dba-43fa-9aa1-d1fe7c5dc7c4"
export const TitleF1a121e1676b4f7bB03486ba4a4f3333 = "Secondary Marketing Title 2";

// id: "05836a2f-6e4a-4ff6-892f-3037aaa1f10d"
// title: ""
// type: :text
// key: "title"
// parent_id: "5eae32f6-79b0-448d-a6e6-dd6d644d8a83"
export const Title05836a2f6e4a4ff6892f3037aaa1f10d = "About Us";

// id: "4e1e1b83-69f7-4fc4-a4b9-95008afa617e"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopBf5b0da391c04d2bBb163d3294906847 parentTag="HeaderTopC3e23004Cf5c43f29b234b61321fc84d" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHeroC36ed3d7411641e285bfC96dbc2a6bc0 parentTag="Hero8af1dfdf2dba43fa9aa1D1fe7c5dc7c4" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "f38f0e50-f83f-4c3a-bf0d-c5e30dfa43af"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "f864390b-920c-4cd4-ad82-6381809b2638"
export const BackgroundImageF38f0e50F83f4c3aBf0dC5e30dfa43af = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "8eabe1a1-6b95-45c4-b4ff-9d8ce8794615"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "f720abba-322a-4cc1-8355-75a29292259a"
export function MarketingCards8eabe1a16b9545c4B4ff9d8ce8794615(props: any) {
  return (
    <MarketingCards048e2672332743e49ca63ee4009898ae parentTag="MarketingCards8eabe1a16b9545c4B4ff9d8ce8794615" postSlug="home-page-posts2" {...props} />
  );
}

// id: "92bb257f-c2fc-412f-972b-187916821fb3"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "a16cb503-bb56-4e68-85c3-9fbb57507120"
export function Button92bb257fC2fc412f972b187916821fb3(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button92bb257fC2fc412f972b187916821fb3", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "e93b6be1-eda8-4890-9844-39422e20be5d"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c8f28ac4-f456-4b28-8bc8-f5eb1e77e87f"
export function HeaderE93b6be1Eda84890984439422e20be5d(props: any) {
  return (
    <SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e parentTag="HeaderE93b6be1Eda84890984439422e20be5d" title="Public Events" {...props} />
  );
}

// id: "09497227-46ee-4a6f-9a2e-c2210d112939"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "e1dc4038-93d8-4a34-bca7-032d6974367e"
export function Footer0949722746ee4a6f9a2eC2210d112939(props: any) {
  return (
    <DefaultFooter04031b2680314f888af1B82289fb5995 parentTag="Footer0949722746ee4a6f9a2eC2210d112939" {...props} />
  );
}

// id: "9da77c1a-d140-44c2-b0c3-3e077810c491"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "ca9970b9-3a89-45d7-80de-fcdb7d00a09c"
export const NavMenuSlug9da77c1aD14044c2B0c33e077810c491 = "members-primary-nav";

// id: "118c1e0b-ce44-4953-a3be-12e59b44a254"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "27071b54-b68d-425f-8568-357957fbe0e7"
export const NavMenuSlug118c1e0bCe444953A3be12e59b44a254 = "marketing-primary-nav";

// id: "c842c526-7591-4f31-b698-336c936ad7ff"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "8eabe1a1-6b95-45c4-b4ff-9d8ce8794615"
export const PostSlugC842c52675914f31B698336c936ad7ff = "home-page-posts2";

// id: "cd6e1105-a669-43c1-826d-0bd89712463a"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "a9f4299c-f6ca-4068-9498-317c68535074"
export function FooterCd6e1105A66943c1826d0bd89712463a(props: any) {
  return (
    <DefaultFooter04031b2680314f888af1B82289fb5995 parentTag="FooterCd6e1105A66943c1826d0bd89712463a" {...props} />
  );
}

// id: "e933e9c8-4bc1-449c-abdd-183df3a603bf"
// title: ""
// type: :reference
// key: "label"
// parent_id: "5af8a170-3994-4d59-8928-161d70e58455"
export function LabelE933e9c84bc1449cAbdd183df3a603bf(props: any) {
  return (
    <DefaultLogoA95cd88c2c904920A94004eb36e311d9 parentTag="LabelE933e9c84bc1449cAbdd183df3a603bf" {...props} />
  );
}

// id: "f2c7983b-2850-4fab-baf0-bf856ac0a39c"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "d8208fdd-b6d2-4464-84af-5817638216dd"
export const PostSlugF2c7983b28504fabBaf0Bf856ac0a39c = "about-us-posts";

// id: "27071b54-b68d-425f-8568-357957fbe0e7"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "bf5b0da3-91c0-4d2b-bb16-3d3294906847"
export function Navigation27071b54B68d425f8568357957fbe0e7(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation27071b54B68d425f8568357957fbe0e7", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "5eae32f6-79b0-448d-a6e6-dd6d644d8a83"
// title: ""
// type: :reference
// key: "header"
// parent_id: "bb38bb63-063a-4e35-b577-f30fd1fa8fb6"
export function Header5eae32f679b0448dA6e6Dd6d644d8a83(props: any) {
  return (
    <SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e parentTag="Header5eae32f679b0448dA6e6Dd6d644d8a83" title="About Us" {...props} />
  );
}

// id: "c3e23004-cf5c-43f2-9b23-4b61321fc84d"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "4e1e1b83-69f7-4fc4-a4b9-95008afa617e"
export function HeaderTopC3e23004Cf5c43f29b234b61321fc84d(props: any) {
  return (
    <DefaultHeaderTopBf5b0da391c04d2bBb163d3294906847 parentTag="HeaderTopC3e23004Cf5c43f29b234b61321fc84d" {...props} />
  );
}

// id: "e3c30929-7cd9-4d63-80da-d6889020c3fc"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "c8f28ac4-f456-4b28-8bc8-f5eb1e77e87f"
export function FooterE3c309297cd94d6380daD6889020c3fc(props: any) {
  return (
    <DefaultFooter04031b2680314f888af1B82289fb5995 parentTag="FooterE3c309297cd94d6380daD6889020c3fc" {...props} />
  );
}

// id: "689d635b-45f6-4e74-a453-066a92d8d398"
// title: ""
// type: :text
// key: "title"
// parent_id: "c36ed3d7-4116-41e2-85bf-c96dbc2a6bc0"
export const Title689d635b45f64e74A453066a92d8d398 = "Default Title";

// id: "03d2b82a-8232-476d-adc4-d642ddb16d5c"
// title: ""
// type: :reference
// key: "header"
// parent_id: "610bd52e-e1b1-4470-b0a8-0987010ea33b"
export function Header03d2b82a8232476dAdc4D642ddb16d5c(props: any) {
  return (
    <SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e parentTag="Header03d2b82a8232476dAdc4D642ddb16d5c" title="Resources" {...props} />
  );
}

// id: "c3ed5567-a3f4-4b61-8e99-d7af5491eeb2"
// title: ""
// type: :text
// key: "title"
// parent_id: "e14dfc46-e721-477d-927b-39a6a02a4221"
export const TitleC3ed5567A3f44b618e99D7af5491eeb2 = "Join Us";

// id: "c86209b6-b9b6-41cc-a803-181088267dbe"
// title: ""
// type: :text
// key: "title"
// parent_id: "15524cf7-0a19-42fc-adeb-9b76f7f42ffa"
export const TitleC86209b6B9b641ccA803181088267dbe = "Leveling";

// id: "ec98f168-fa10-48f6-aa9d-5c6b5a2f6f1d"
// title: ""
// type: :text
// key: "title"
// parent_id: "03d2b82a-8232-476d-adc4-d642ddb16d5c"
export const TitleEc98f168Fa1048f6Aa9d5c6b5a2f6f1d = "Resources";

// id: "33837baf-1400-425c-9021-5a5ff5d0d2f2"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "4c2bb396-57a5-474b-a35b-a14257df8c0b"
export const BackgroundImage33837baf1400425c90215a5ff5d0d2f2 = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp";

// id: "effef9c7-c97e-459f-941d-df47b8394de7"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePageEffef9c7C97e459f941dDf47b8394de7(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageEffef9c7C97e459f941dDf47b8394de7 page`}>
      {props["header"] || <HomeHeader4c2bb39657a5474bA35bA14257df8c0b parentTag="Header7c7abcd6860b4f1b86699f24ef20fc3b" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack42fb0eabF97248d5B8e549c38d07c16b", parentTag)} {...props} />}
      {props["body"] || <MarketingBodyF720abba322a4cc1835575a29292259a parentTag="Body8e4259f0C0fd42cdB7039f83b8215143" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("Quote75b58819B0c44a3c8a4228246dd5763e", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooter04031b2680314f888af1B82289fb5995 parentTag="Footer58bed9adC8be48da9f9a319944725db8" {...props} />}
    </div>
  );
}

// id: "2a6b0f7c-33ce-49c3-967e-0acf446e1688"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "f864390b-920c-4cd4-ad82-6381809b2638"
export function Navigation2a6b0f7c33ce49c3967e0acf446e1688(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation2a6b0f7c33ce49c3967e0acf446e1688", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "acb582b3-47b6-4d8e-bf3c-90613b5d6cbe"
// title: ""
// type: :html
// key: "logo"
// parent_id: "04031b26-8031-4f88-8af1-b82289fb5995"
export function LogoAcb582b347b64d8eBf3c90613b5d6cbe(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide-white-with-font.svg" alt="Demo 1 logo" className={`${parentTag || ""} LogoAcb582b347b64d8eBf3c90613b5d6cbe logo`} />
  );
}

// id: "c7c3b22a-0778-4385-b11d-0d12d346008a"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "83871dd2-6cde-4608-a866-45fa58c5f144"
export function HeaderTopC7c3b22a07784385B11d0d12d346008a(props: any) {
  return (
    <DefaultHeaderTopBf5b0da391c04d2bBb163d3294906847 parentTag="HeaderTopC7c3b22a07784385B11d0d12d346008a" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "e14dfc46-e721-477d-927b-39a6a02a4221"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c4571c03-b07b-4c12-9cf2-43b440d43242"
export function HeaderE14dfc46E721477d927b39a6a02a4221(props: any) {
  return (
    <SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e parentTag="HeaderE14dfc46E721477d927b39a6a02a4221" title="Join Us" {...props} />
  );
}

// id: "9148ac9c-3a4f-4a5d-a874-14c3fa8bd9e5"
// title: ""
// type: :text
// key: "alt"
// parent_id: "a95cd88c-2c90-4920-a940-04eb36e311d9"
export const Alt9148ac9c3a4f4a5dA87414c3fa8bd9e5 = "logo";

// id: "f1f1fb69-0bf8-4b64-8e97-10cf312d470d"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "04031b26-8031-4f88-8af1-b82289fb5995"
export function GroupflowF1f1fb690bf84b648e9710cf312d470d(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("GroupflowF1f1fb690bf84b648e9710cf312d470d", parentTag)} {...props} />
  );
}

// id: "a8932a10-b30c-4fc7-b142-50a306dac4e0"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "eb93e62e-c7d4-4ebf-a9b0-bbeb21503a41"
export const NavMenuSlugA8932a10B30c4fc7B14250a306dac4e0 = "marketing-primary-nav";

// id: "d8208fdd-b6d2-4464-84af-5817638216dd"
// title: ""
// type: :reference
// key: "body"
// parent_id: "bb38bb63-063a-4e35-b577-f30fd1fa8fb6"
export function BodyD8208fddB6d2446484af5817638216dd(props: any) {
  return (
    <MarketingBodyF720abba322a4cc1835575a29292259a parentTag="BodyD8208fddB6d2446484af5817638216dd" postSlug="about-us-posts" {...props} />
  );
}

// id: "75b58819-b0c4-4a3c-8a42-28246dd5763e"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "effef9c7-c97e-459f-941d-df47b8394de7"
export function Quote75b58819B0c44a3c8a4228246dd5763e(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote75b58819B0c44a3c8a4228246dd5763e", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "231915fd-31a0-4289-a80a-04228a9db457"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "a95cd88c-2c90-4920-a940-04eb36e311d9"
export const ImageUrl231915fd31a04289A80a04228a9db457 = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg";

// id: "e42b1e7e-e4ec-4a71-a9f9-8121eedb6a85"
// title: ""
// type: :text
// key: "title"
// parent_id: "4e1e1b83-69f7-4fc4-a4b9-95008afa617e"
export const TitleE42b1e7eE4ec4a71A9f98121eedb6a85 = "Secondary Marketing Title 1";

// id: "268afec0-13ef-4c06-931e-204a401c79f8"
// title: ""
// type: :reference
// key: "label"
// parent_id: "85f13516-1d99-457d-8a28-b50c4f15ed2d"
export function Label268afec013ef4c06931e204a401c79f8(props: any) {
  return (
    <DefaultLogoA95cd88c2c904920A94004eb36e311d9 parentTag="Label268afec013ef4c06931e204a401c79f8" {...props} />
  );
}

// id: "9941caef-3848-427a-bf47-8913cdec37a4"
// title: ""
// type: :text
// key: "title"
// parent_id: "5d419559-41f5-49b8-948d-f94bada593e3"
export const Title9941caef3848427aBf478913cdec37a4 = "Join Us";

// id: "8a50bea5-00c0-491c-8267-7de94c6f9d0f"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "2a6b0f7c-33ce-49c3-967e-0acf446e1688"
export const NavMenuSlug8a50bea500c0491c82677de94c6f9d0f = "members-primary-nav";

// id: "41017c01-6500-47a9-87ed-ea8e02dbede4"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "4c2bb396-57a5-474b-a35b-a14257df8c0b"
export function HeaderTop41017c01650047a987edEa8e02dbede4(props: any) {
  return (
    <DefaultHeaderTopBf5b0da391c04d2bBb163d3294906847 parentTag="HeaderTop41017c01650047a987edEa8e02dbede4" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />
  );
}

// id: "4c2bb396-57a5-474b-a35b-a14257df8c0b"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader4c2bb39657a5474bA35bA14257df8c0b(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader4c2bb39657a5474bA35bA14257df8c0b site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopBf5b0da391c04d2bBb163d3294906847 parentTag="HeaderTop41017c01650047a987edEa8e02dbede4" widgetId="6c4e6e5d-e227-4526-9c6a-cdd2f3acafb5" {...props} />}
      </div>
      {props["hero"] || <HomePageHeroA16cb503Bb564e6885c39fbb57507120 parentTag="Hero23daed14737f4b4bA0fbDea20e35ce96" widgetId="4cae07c2-4aaf-4224-bcc4-7f4e0b10d11d" {...props} />}
    </div>
  );
}

const Components = {
  MarketingCards048e2672332743e49ca63ee4009898ae,
  WidgetId08f22a0a84f940d3B3f54b1989f6a81d,
  Title40985e293f3d4989Ae06398da5804f05,
  WidgetIdD9c68fe5608f46a09982F2a3e042bcae,
  AdminPages38f60a6dC3ce4c37B1ec81429b3a1db8,
  DefaultHeaderEb93e62eC7d44ebfA9b0Bbeb21503a41,
  MembersAreaHeader83871dd26cde4608A86645fa58c5f144,
  MarketingBodyF720abba322a4cc1835575a29292259a,
  AboutUsPageBb38bb63063a4e35B577F30fd1fa8fb6,
  PublicEventsPageC8f28ac4F4564b288bc8F5eb1e77e87f,
  Links78ffd4ad818e45d6B2e57f35ce225f15,
  Hero8af1dfdf2dba43fa9aa1D1fe7c5dc7c4,
  MemberPagesF9a32d4e24cd46ccA2a5C9df273da98f,
  MembersAreaDefault89b9d7cc508949a4A2ae70b87919316c,
  SecondaryPageE1dc403893d84a34Bca7032d6974367e,
  ResourcesPage610bd52eE1b14470B0a80987010ea33b,
  LabelF4d52eb4Db3a4235Bb8f126125d261a1,
  ShortHeaderF864390b920c4cd4Ad826381809b2638,
  DefaultLogoA95cd88c2c904920A94004eb36e311d9,
  PageQuote8b5ba94fE2c244cdBe7e4087307d45e2,
  JoinUsPageC4571c03B07b4c129cf243b440d43242,
  HrefC8896faf2702483e9d9d12179ef7c79e,
  Footer1f3b572f228247d6922eA265fc878aec,
  SignupPagesD05e15ce000d46a0A462E3d0ab8b9bb3,
  SecondaryMarketingHeroC36ed3d7411641e285bfC96dbc2a6bc0,
  HeaderB5f4632bD8a141f0A50a7c03ce06f2f6,
  Footer4208185cE55743b1Be4970cd98577263,
  HeaderCa9970b93a8945d780deFcdb7d00a09c,
  Header1e300fbcE6e94396Bee5536ac096b405,
  Header7c7abcd6860b4f1b86699f24ef20fc3b,
  NavMenuSlugDa5f0c132bbe43b695ee8038e5f4a11b,
  Href32f6cfa83cae4c2e914a96576c3c6bea,
  Title61ff11632b0b40198d33956427ed8bae,
  DefaultFooter04031b2680314f888af1B82289fb5995,
  WelcomeBack42fb0eabF97248d5B8e549c38d07c16b,
  FooterFbf41fc333f04778A22e770d72e4551a,
  Footer1b8bbf8488994dfa80b9C40a69673f44,
  Footer58bed9adC8be48da9f9a319944725db8,
  Header4b7bdd415d1240b48ea2E2caaf917ec2,
  PostSlug45459fc27e9542b9907f66d83b16aaa4,
  Label7e9c380a7b414d5bB0fe888c3b6b42bc,
  UrlC5e5d9634753445a8d926b70ac66f97f,
  Class1781d1e9Fdb94a73A8b8Ad81f5aa6a67,
  DefaultHeaderTopBf5b0da391c04d2bBb163d3294906847,
  AboutLevelingPageA9f4299cF6ca40689498317c68535074,
  HeaderF9f3180c8b864fa6B5a11075acafeaeb,
  LinkedLogo85f135161d99457d8a28B50c4f15ed2d,
  Body8e4259f0C0fd42cdB7039f83b8215143,
  Header15524cf70a1942fcAdeb9b76f7f42ffa,
  ContentSlug1d2bbe5709ac43ab94e995f80b380ecf,
  Title7c249bf28a33493bB62c70f9d1af8593,
  NavMenuSlugFa74a19aE09340af896001d664336f05,
  Hero23daed14737f4b4bA0fbDea20e35ce96,
  MessagePages2218919406f74062Ba7fDe67c1fc1efd,
  HomePageHeroA16cb503Bb564e6885c39fbb57507120,
  ButtonClassDa87b3bfA6f64842A4fcDb5799ccf5ef,
  SecondaryNav9986106bB8da4dd4Bf24D3f29dea444d,
  Copyright1ce0cc7fBb4e45f19a7f6ee26677b84c,
  LinkedLogo5af8a17039944d598928161d70e58455,
  Header5d41955941f549b8948dF94bada593e3,
  Title19d1103eA9c14027Ad18C10d788160d1,
  Class2712ae38Cb4346b28097440873b1fd74,
  TitleF1a121e1676b4f7bB03486ba4a4f3333,
  Title05836a2f6e4a4ff6892f3037aaa1f10d,
  SecondaryMarketingHeader4e1e1b8369f74fc4A4b995008afa617e,
  BackgroundImageF38f0e50F83f4c3aBf0dC5e30dfa43af,
  MarketingCards8eabe1a16b9545c4B4ff9d8ce8794615,
  Button92bb257fC2fc412f972b187916821fb3,
  HeaderE93b6be1Eda84890984439422e20be5d,
  Footer0949722746ee4a6f9a2eC2210d112939,
  NavMenuSlug9da77c1aD14044c2B0c33e077810c491,
  NavMenuSlug118c1e0bCe444953A3be12e59b44a254,
  PostSlugC842c52675914f31B698336c936ad7ff,
  FooterCd6e1105A66943c1826d0bd89712463a,
  LabelE933e9c84bc1449cAbdd183df3a603bf,
  PostSlugF2c7983b28504fabBaf0Bf856ac0a39c,
  Navigation27071b54B68d425f8568357957fbe0e7,
  Header5eae32f679b0448dA6e6Dd6d644d8a83,
  HeaderTopC3e23004Cf5c43f29b234b61321fc84d,
  FooterE3c309297cd94d6380daD6889020c3fc,
  Title689d635b45f64e74A453066a92d8d398,
  Header03d2b82a8232476dAdc4D642ddb16d5c,
  TitleC3ed5567A3f44b618e99D7af5491eeb2,
  TitleC86209b6B9b641ccA803181088267dbe,
  TitleEc98f168Fa1048f6Aa9d5c6b5a2f6f1d,
  BackgroundImage33837baf1400425c90215a5ff5d0d2f2,
  HomePageEffef9c7C97e459f941dDf47b8394de7,
  Navigation2a6b0f7c33ce49c3967e0acf446e1688,
  LogoAcb582b347b64d8eBf3c90613b5d6cbe,
  HeaderTopC7c3b22a07784385B11d0d12d346008a,
  HeaderE14dfc46E721477d927b39a6a02a4221,
  Alt9148ac9c3a4f4a5dA87414c3fa8bd9e5,
  GroupflowF1f1fb690bf84b648e9710cf312d470d,
  NavMenuSlugA8932a10B30c4fc7B14250a306dac4e0,
  BodyD8208fddB6d2446484af5817638216dd,
  Quote75b58819B0c44a3c8a4228246dd5763e,
  ImageUrl231915fd31a04289A80a04228a9db457,
  TitleE42b1e7eE4ec4a71A9f98121eedb6a85,
  Label268afec013ef4c06931e204a401c79f8,
  Title9941caef3848427aBf478913cdec37a4,
  NavMenuSlug8a50bea500c0491c82677de94c6f9d0f,
  HeaderTop41017c01650047a987edEa8e02dbede4,
  HomeHeader4c2bb39657a5474bA35bA14257df8c0b
}

export default Components;